import React from "react"
import ReactDOM from "react-dom/client"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"

import App from "./App"
import CssBaseline from "@mui/material/CssBaseline"
import { createTheme } from "@mui/material/styles"
import { ThemeProvider } from "@emotion/react"
import * as Sentry from "@sentry/react";
import "./global.css"


Sentry.init({
    dsn: "https://8e93fc6d8ece4e624f1d6a7bb2e4a058@o4506484705329152.ingest.sentry.io/4506484919435264",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const theme = createTheme({
    palette: {
        primary: {
            main: "#3538BB",
            light: "#E9F4FF",
        },
    },

    typography: {
        fontFamily: ["SUIT-Regular"].join(","),
        h6: {
            fontSize: "16px",
            fontWeight: 700,
            color: "var(--black)",
        },
    },
})

root.render(
    <>
        {/* <React.StrictMode> */}
        <CssBaseline />
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
        {/* </React.StrictMode> */}
    </>
)

serviceWorkerRegistration.register()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
reportWebVitals(console.log)
