import {Box, CircularProgress, Typography} from "@mui/material"
import {useNavigate, useParams} from "react-router-dom"
import MessageForm from "../components/chat/MessageForm"
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react"
import {IChatMeta, IMessage, IReservation} from "../type"
import {Messages} from "../components/chat/Messages"
import ChatHeader from "../components/layout/ChatHeader"
import {getDevice, separateMessages, subscribeToNewMessages, updateReadStatusMessage} from "../hooks/chat"
import Loading from "../components/ui/Loading"
import {deleteReservationCache, getCachedReservation, setReservationCache} from "../hooks/cacheStorage"
import {callFunction, getDocData, reportError} from "../hooks/firebase"
import {useChatContext} from "../context/ChatContext"
import CloseIcon from "@mui/icons-material/Close"
// todo Indexdb
// import { CHAT_STORAGE_NAME, getMessagesFromIndexedDB, openIndexedDB, saveMessagesToIndexedDB } from "../hooks/indexedDB"

export default function Chat() {
    const [loading, setLoading] = useState(false)
    const [rawMessages, setRawMessages] = useState<IMessage[]>([])
    const memoMessages = useMemo(() => separateMessages(rawMessages), [rawMessages])

    const messagesRef = useRef<HTMLInputElement>(null)

    const {id: chatId} = useParams() as { id: string }
    const [reservation, setReservation] = useState<IReservation | null | undefined>(undefined)
    const {state: chatState, dispatch: chatDispatch} = useChatContext()

    const navigate = useNavigate()
    useLayoutEffect(() => {
        async function init() {
            const resp = await getCachedReservation("/reservationId")
            if (!resp?.id) {
                setReservation(null)
                return
            }

            const reservation = await getCachedReservation(`/${resp.id}`)
            const isValid = new Date(reservation?.date ?? "2000-01-01") > new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
            if (!reservation || !isValid) {
                deleteReservationCache(`/${resp.id}`).catch(console.error);
                setReservation(null);
                navigate("/")
                alert("Please check your reservation Id.\r\nAccess is valid only within 14 days.")
                return
            }

            const chatMeta: IChatMeta = await getDocData(["chats", chatId])
            if (!chatMeta.participantIds.includes(reservation.id)) {
                navigate("/")
                alert("Please check your reservation Id.\r\nAccess is valid only within 14 days.")
                return
            }
            setReservation(reservation)
        }

        init()
    }, [])

    useEffect(() => {
        if (!reservation) return
        updateReadStatusMessage(chatId, reservation.id)
    }, [rawMessages, reservation])

    useEffect(() => {
        const scrollEl = messagesRef.current
        if (!scrollEl) return

        const images: any = scrollEl.querySelectorAll(".MuiImageList-root li img")
        if (images.length) {
            images[images.length - 1].loading = "eager"
            images[images.length - 1].addEventListener("load", () => {
                scrollEl.scrollTop = scrollEl.scrollHeight
            })
        }
        scrollEl.scrollTop = scrollEl.scrollHeight
    }, [rawMessages, reservation])

    useEffect(() => {
        if (!chatId || !reservation?.id) return
        getDevice()
            .then((token) => {
                if (token) {
                    callFunction("registerDeviceForChat", {
                        chatId,
                        participantId: reservation.id,
                        device: token,
                    })
                        .then(() => {
                        })
                        .catch(console.error)
                } else {
                    console.log("No update")
                }
            })
            .catch((e) => reportError(e))
    }, [reservation?.id, chatId])

    useEffect(() => {
        const unsub = subscribeToNewMessages(chatId, new Date(0), (querySnapshot) => {
            const messages = querySnapshot.docs.map(
                (doc) =>
                    ({
                        id: doc.id,
                        ...doc.data(),
                    }) as IMessage
            )
            setRawMessages(messages)
        })
        return () => {
            if (unsub) unsub()
        }

        // let indexedDB: any = null
        // let unsub: any = null
        // let newMessages: any[] = []
        // let oldMessages: any[] = []
        //
        // openIndexedDB(CHAT_STORAGE_NAME)
        //     .then((openDB) => {
        //         indexedDB = openDB
        //         return getMessagesFromIndexedDB(indexedDB, chatId)
        //     })
        //     .then((messages) => {
        //         const lastMessage = messages.pop()
        //         oldMessages = messages
        //         const lastMessageDate = lastMessage ? new Date(lastMessage.date.seconds * 1000) : new Date(0)
        //         unsub = subscribeToNewMessages(chatId, lastMessageDate, (querySnapshot) => {
        //             newMessages = querySnapshot.docs.map(
        //                 (doc) =>
        //                     ({
        //                         id: doc.id,
        //                         ...doc.data(),
        //                     }) as IMessage
        //             )
        //                         const sortedMessages = [...oldMessages, ...newMessages].sort((a, b) => {
        //                             return a.date > b.date ? 1 : 0;
        //                         });
        //                         setRawMessages(sortedMessages);
        //         })
        //     })
        //     .catch((err) => {
        //         console.error("Error handling IndexedDB or Firestore:", err)
        //     })
        //
        // return () => {
        //     if (unsub) unsub()
        //     if (indexedDB) {
        //         saveMessagesToIndexedDB(indexedDB, chatId, [...oldMessages, ...newMessages])
        //         indexedDB.close()
        //     }
        // }
    }, [])

    const onClearReply = () => {
        chatDispatch({
            type: "CLEAR_REPLY",
        })
    }

    if (typeof reservation === "undefined") return <Loading center="absolute"/>
    if (!reservation) {
        navigate("/")
        alert("Please check your reservation Id.\r\nAccess is valid only within 14 days.")
        return null
    }

    return (
        <Box
            ref={messagesRef}
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                height: "100dvh",
                backgroundColor: "#FAFAFA",
                overflowY: "scroll",
            }}
        >
            <ChatHeader title={"Chat with your tour!"} reservation={reservation} chatId={chatId}/>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "12px",
                    flex: 1,
                    px: 2.5,
                    py: 2.75,
                }}
            >
                {loading ? (
                    <Loading center="absolute"/>
                ) : (
                    memoMessages.map((messages, index) => (
                        // type === 'reply' if click scroll to message
                        <Messages
                            key={index + ""}
                            messages={messages}
                            reservation={reservation}
                            setLoading={setLoading}
                        />
                    ))
                )}
            </Box>
            <Box
                sx={{
                    position: "sticky",
                    left: 0,
                    bottom: 0,
                }}
            >
                {chatState.reply.id && (
                    <Box
                        sx={{
                            padding: "10px 20px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            color: "#444",
                            background: "#fff",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="h6" fontSize="18px" fontWeight="600" color="black">
                                Reply Message
                            </Typography>
                            <CloseIcon onClick={onClearReply}/>
                        </Box>
                        {chatState.reply.text ?? ''}
                    </Box>
                )}
                <MessageForm
                    sx={{
                        display: "flex",
                        justifyContent: "between",
                        alignItems: "center",
                        py: 1.25,
                        width: "100%",
                        background: "#fff",
                        columnGap: "8px",
                        px: 2.5,
                    }}
                    chatId={chatId}
                    reservation={reservation}
                />
            </Box>
        </Box>
    )
}
