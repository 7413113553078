import { Box, CircularProgress } from "@mui/material"

interface Props {
    center: "absolute" | "flex"
}
export default function Loading({ center }: Props) {
    const style = {
        absolute: {
            posintion: "absolute",
            inset: 0,
            margin: "auto",
        },
        flex: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
        },
    }
    return (
        <Box sx={center === "absolute" ? style.absolute : style.flex}>
            <CircularProgress
                sx={{
                    position: "absolute",
                    inset: 0,
                    margin: "auto",
                }}
            />
        </Box>
    )
}
