export const getUserDevice = () => {
    const ua = navigator.userAgent

    // Patterns to identify device
    const isiPhone = /iPhone/i.test(ua)
    const isAndroid = /Android/i.test(ua)
    const isDesktop = /Win|Mac|Linux/i.test(ua)
    const isLaptop = isDesktop && (window.screen.width < 1280 || window.screen.height < 800) // 가정: 랩탑은 일반적으로 데스크탑보다 작은 화면을 가짐

    // Determine the device
    if (isiPhone) {
        return "iphone"
    } else if (isAndroid) {
        return "android"
    } else if (isLaptop) {
        return "laptop"
    } else if (isDesktop) {
        return "desktop"
    } else {
        return "unknown"
    }
}

export const getUserBrowser = () => {
    const ua = navigator.userAgent
    // Patterns to identify browser
    const isSafari = /Safari/i.test(ua) && !/Chrome/i.test(ua) && !/CriOS/i.test(ua)
    const isChrome = /Chrome/i.test(ua) || /Chromium/i.test(ua) || /CriOS/i.test(ua)
    const isSamsung = /SamsungBrowser/i.test(ua)
    const isOpera = /Opera|OPR/i.test(ua)

    // Determine the browser
    if (isSafari) {
        return "safari"
    } else if (isChrome) {
        return "chrome"
    } else if (isSamsung) {
        return "samsung"
    } else if (isOpera) {
        return "opera"
    } else {
        return "unknown"
    }
}
