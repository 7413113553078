import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import SendIcon from "@mui/icons-material/Send"
import { Box, IconButton, OutlinedInput, SxProps, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { sendMessage } from "../../hooks/chat"
import ImageUploadSwiper from "./ImageUploadSwiper"
import { IReservation } from "../../type"
import { useChatContext } from "../../context/ChatContext"

interface IProps {
    sx?: SxProps
    reservation: IReservation
    chatId: string
}

interface IForm {
    text: string
}

export default function MessageForm({ sx, reservation, chatId }: IProps) {
    const [openSwiper, setOpenSwiper] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const messageFormRef = useRef<HTMLInputElement>(null)
    const { register, reset, handleSubmit, watch } = useForm<IForm>()
    const { state: chatState, dispatch: chatDispatch } = useChatContext()

    async function onSendMessage({ text }: IForm) {
        if (messageFormRef.current) messageFormRef.current.focus()
        if (isSending || text?.trim() === "") return

        setIsSending(true)

        await sendMessage(
            chatId,
            {
                type: chatState.reply.id ? "reply" : "text",
                text,
            },
            reservation,
            chatState.reply.id ? chatState.reply : undefined
        )
        reset()
        setIsSending(false)
        chatDispatch({
            type: "CLEAR_REPLY",
        })
    }

    async function checkKeyDown(event: any) {
        const userAgent = window.navigator.userAgent
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
        if (
            !mobile &&
            !isSending &&
            event.key === "Enter" &&
            event.nativeEvent.isComposing === false &&
            !event.shiftKey
        ) {
            event.preventDefault()
            const data = {
                text: event.target.value,
            }
            onSendMessage(data)
            return
        }
    }

    // useEffect(() => {
    //     const handler = (event: any) => {
    //         if (messagesRef.current && !messagesRef.current.contains(event.target)) {
    //             if (messageFormRef.current) messageFormRef.current.blur()
    //         }
    //     }
    //     document.addEventListener("touchmove", handler)
    //     return () => {
    //         document.removeEventListener("touchmove", handler)
    //     }
    // }, [messageFormRef, messagesRef])

    return (
        <>
            <form onSubmit={handleSubmit(onSendMessage)}>
                <Box sx={sx}>
                    <AddPhotoAlternateIcon
                        sx={{
                            width: "42px",
                            height: "42px",
                            fill: "#bdbdbd",
                        }}
                        onClick={() => setOpenSwiper(true)}
                    />

                    <Box
                        sx={{
                            maxHeight: "20vh",
                            overflowY: "scroll",
                            scrollbarWidth: "none",
                            "&-ms-overflow-style": "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            width: "100%",
                        }}
                    >
                        <TextField
                            inputRef={messageFormRef}
                            variant="standard"
                            multiline={true}
                            fullWidth
                            // placeholder={images.length > 0 ? "이미지" : "메시지를 입력하세요."}
                            // disabled={images.length > 0}
                            sx={{
                                flex: 1,

                                padding: "10px 20px",
                                background: "#FAFAFA",
                                borderRadius: "10px",
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            onKeyDown={checkKeyDown}
                            {...register("text")}
                        />
                    </Box>

                    <IconButton
                        type="submit"
                        style={{
                            borderRadius: "50%",
                            height: "48px",
                            width: "48px",
                            background: watch("text") ? "#3538bb" : "#e0e0e0",
                            transform: "rotate(-40deg)",
                        }}
                    >
                        <SendIcon
                            sx={{
                                width: "32px",
                                height: "32px",
                                fill: "#fff",
                            }}
                        />
                    </IconButton>
                </Box>
            </form>
            <ImageUploadSwiper
                anchor="bottom"
                open={openSwiper}
                onOpen={() => {
                    setOpenSwiper(true)
                }}
                setOpen={setOpenSwiper}
                onClose={() => setOpenSwiper(false)}
                reservation={reservation}
                chatId={chatId}
            />
        </>
    )
}
