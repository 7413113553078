import React, {useState} from "react"
import {Box, ButtonBase, Modal, Typography} from "@mui/material"
import {downloadFileFromStorage} from "../../hooks/firebase"
import DownloadIcon from "@mui/icons-material/Download"

interface Props {
    open: boolean
    onClose: () => void
    fileUrl: string
}

export default function ImagePreviewer({open, fileUrl, onClose}: Props) {
    const [failDownload, setFailDownload] = useState<boolean>(false);
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                background: "rgba(0, 0, 0, 0.6)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    color: 'white',
                    '&:focus': {
                        outline: `0px none`,
                        borderColor: `#000000`,
                    },
                }}
            >
                <img
                    style={{
                      maxWidth: '90vw',
                      maxHeight: '70vh',
                      minWidth: '100px',
                      minHeight: '100px',
                      background: 'black',
                      objectFit: 'contain',
                      color: 'white',
                    }}
                    src={fileUrl}
                    alt="Browser doesn't support this format, but still downloadable."

                />
                {
                    failDownload
                        ? <Typography textAlign={'center'} color={'inherit'}>
                            Long press to save image
                            <br/>
                            长按保存图片
                        </Typography>
                        : (
                            <ButtonBase onClick={() => {
                                downloadFileFromStorage(fileUrl).catch(() => {
                                    setFailDownload(true);
                                })
                            }}>
                                <DownloadIcon
                                    sx={{
                                        color: "#fff",
                                    }}
                                />
                            </ButtonBase>
                        )
                }
            </Box>
        </Modal>
    )
}
