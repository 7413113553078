import { Box, Button, TextField, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect } from "react"
import { getCachedReservation, setReservationCache } from "../hooks/cacheStorage"

type ReservationInput = {
    id: string
}

export default function Home() {
    const { register, watch, setValue, handleSubmit } = useForm<ReservationInput>()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        async function getReservationId() {
            const resp = await getCachedReservation("/reservationId")
            if (!resp || !resp.hasOwnProperty("id")) return

            setValue("id", resp.id)
            navigate(`/reservation?id=${resp.id}`, {
                state: {
                    reservationId: resp.id,
                },
            })
        }

        if (location.state?.notFound) return
        getReservationId()
    }, [])
    const onSubmitReservationId = async () => {
        const reservationId = watch("id")
        const response = new Response(JSON.stringify({ id: reservationId }), { status: 200 })
        await setReservationCache("/reservationId", response)
        navigate(`/reservation?id=${reservationId}`, {
            state: {
                reservationId,
            },
        })
    }

    return (
        <Box
            sx={{
                height: "100dvh",
                position: "relative",
                px: 2.5,
            }}
        >
            <Box
                sx={{
                    py: 1.5,
                }}
            >
                <CloseIcon
                    sx={{
                        width: 32,
                        height: 32,
                    }}
                />
            </Box>
            <Box
                sx={{
                    py: 2,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        color: "#4F4F4F",
                    }}
                >
                    Hi, Please let me know
                </Typography>
                <Typography
                    sx={{
                        fontSize: 24,
                        fontWeight: 700,
                        color: "#2A2F37",
                    }}
                >
                    Your reservation ID
                </Typography>
            </Box>

            <Box
                sx={{
                    pb: 1.5,
                }}
            >
                <TextField
                    role="reservation-id-input"
                    label="Reservation ID"
                    autoFocus={true}
                    type="search"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{
                        sx: {
                            fontSize: 20,
                            color: "#BDBDBD",
                        },
                    }}
                    inputProps={{
                        sx: {
                            marginTop: "12px",
                        },
                    }}
                    {...register("id", { required: true })}
                />
            </Box>

            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    py: 1.5,
                    px: 2.5,
                    width: "100%",
                }}
            >
                <Button
                    role="reservation-id-submit"
                    variant="contained"
                    fullWidth
                    sx={{
                        fontSize: 14,
                        fontWeight: 700,
                        textTransform: "none",
                        height: 46,
                        borderRadius: "12px",
                    }}
                    onClick={handleSubmit(onSubmitReservationId)}
                >
                    Confirm
                </Button>
            </Box>
        </Box>
    )
}
