import * as React from "react"
import { Box, CircularProgress, Typography } from "@mui/material"
import { IMessage, IReservation } from "../../type"
import Message from "./Message"
import {
    convertSenderName,
    getDevice,
    isInfoMessage,
    parseChatMetadata,
    recallComprehensiveTourChat,
} from "../../hooks/chat"
import InfoMessage from "./InfoMessage"
import { callFunction } from "../../hooks/firebase"
import { useNavigate } from "react-router-dom"
import Modal from "@mui/material/Modal"

type Props = {
    messages: IMessage[]
    reservation: IReservation
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export function Messages({ messages, reservation, setLoading }: Props) {
    const firstMessage = messages[0]
    const sender = firstMessage.sender
    const isMe = firstMessage && sender.id === reservation.id

    const navigate = useNavigate()

    if (isInfoMessage(firstMessage)) {
        return <Box />
        // return <InfoMessage message={firstMessage.text}/>
    }

    const createChat = async () => {
        if (sender.type === "client") return
        setLoading(true)
        try {
            const client: any = {
                id: reservation.id,
                name: reservation.clientName,
                nameEn: "Client",
                type: "client",
            }
            const token = await getDevice().catch((e) => reportError(e))
            if (token) {
                client["device"] = token
                callFunction("registerReservationDevice", { reservationId: reservation.id, device: token })
                    .then(() => {
                    })
                    .catch(console.error)
            }

            const participants = [client, sender]
            const cId = participants
                .map((participant) => participant.id)
                .sort((a, b) => (a > b ? 1 : 0))
                .join("")

            const chatMeta = (await callFunction("recallChat", {
                category: "PERSONAL",
                participants,
                cId,
                title: `${reservation.date}:${reservation.product.name}:${reservation.clientName}`,
                tour: {
                    date: reservation.date,
                    productId: reservation.productId,
                    tourId: reservation.tourId,
                    team: reservation.teamId,
                },
            })) as any

            window.location.href = `/chat/${chatMeta.id}`
        } catch (e) {
            reportError(e)
            navigate("/")
        } finally {
            setLoading(false)
        }
    }
    return (
        <Box
            sx={{
                display: "flex",
                columnGap: "12px",
                flexDirection: isMe ? "row-reverse" : "row",
                my: 1,
            }}
        >
            {/*Profile */}
            {/*{!isMe && <Avatar alt="user profile image" src={sender.photoURL ?? "/logo512.png"} />}*/}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "8px",
                    maxWidth: "90%",
                }}
            >
                {messages?.map((message: any, index: number) => (
                    <Box key={message.id}>
                        {!isMe && index === 0 && (
                            <Typography
                                color="#828282"
                                variant="body1"
                                fontWeight="500"
                                // onClick={() => {
                                //     if (confirm("Do you want to create a chat room with this user?")) createChat()
                                // }}
                                // sx={{
                                //     cursor: "pointer",
                                // }}
                            >
                                {convertSenderName(message.sender)}
                            </Typography>
                        )}

                        <Message
                            reservation={reservation}
                            message={message}
                            isMe={isMe}
                            showTime={index === messages.length - 1}
                            isStart={index === 0}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
