const CACHE_NAME = "ktourstory-client"

export const setReservationCache = async (key: string, data: any) => {
    const cacheStorage = await window.caches.open(CACHE_NAME)
    await cacheStorage.put(key, data)
}

export const deleteReservationCache = async (key: string) => {
    const cacheStorage = await window.caches.open(CACHE_NAME)
    await cacheStorage.delete(key)
}

// key is reservation id
export const getCachedReservation = async (key: string) => {
    const cacheStorage = await window.caches.open(CACHE_NAME)
    const cachedResponse = await cacheStorage.match(key)
    if (!cachedResponse || !cachedResponse.ok) return null

    return await cachedResponse.json();
}
