import {Box, Button, CircularProgress, SwipeableDrawer, SwipeableDrawerProps, Typography} from "@mui/material"
import {red} from "@mui/material/colors"
import Puller from "../ui/Puller"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import React, {ChangeEvent, Dispatch, SetStateAction, useState} from "react"
import FolderIcon from "@mui/icons-material/Folder"
import SendIcon from "@mui/icons-material/Send"
import {sendMessage} from "../../hooks/chat"
import {uploadFileToStorage} from "../../hooks/firebase"
import {v4 as uuidv4} from "uuid"
import heic2any from "heic2any";
import {IReservation} from "../../type"

interface Props extends SwipeableDrawerProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  reservation: IReservation
  chatId: string
}

export default function ImageUploadSwiper({setOpen, reservation, chatId, ...props}: Props) {
  const [files, setFiles] = useState<(File | Blob)[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [isConverting, setIsConverting] = useState(false);
  const [progresses, setProgresses] = useState<number[]>([]);
  const [key, setNewKey] = useState<string>(new Date().toString());

  async function onUpload() {
    if (files.length === 0) return
    setIsUploading(true)

    const fileUrls = await uploadFilesToStorage(files)

    await sendMessage(
        chatId,
        {
          type: "image",
          files: fileUrls,
        },
        reservation
    )
    setNewKey(new Date().toString());
    setProgresses([]);
    setFiles([])
    setOpen(false)
    setIsUploading(false)
  }

  async function uploadFilesToStorage(files: Blob[]) {
    return await Promise.all(files.map((file, idx) => uploadFileToStorage(`chats/${chatId}/${uuidv4()}`, file, (newProgress, transferred, total) => {
      setProgresses((progresses) => {
        const newProgresses = [...progresses]
        newProgresses[idx] = newProgress
        return newProgresses
      });
    }).catch((e) => {
      alert('Cannot upload the file')
      return '';
    })));
  }


  function changeFile(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files instanceof FileList) {
      const targetFiles = [...files, ...e.target.files];
      setIsConverting(true);
      Promise.all(targetFiles.map(async (file) => {
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const uploadable = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 1,
          });
          return Array.isArray(uploadable) ? uploadable[0] : uploadable
        }
        return file;
      })).then((files) => setFiles(files))
          .finally(() => setIsConverting(false));
    }
  }

  function disableSwipeOnChildren(e: any) {
    e.stopPropagation()
  }

  return (
      <SwipeableDrawer {...props} disableSwipeToOpen={true}>
        <Box
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              py: 2,
            }}
        >
          <Puller/>
        </Box>
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              px: 2.5,
            }}
            onTouchStart={disableSwipeOnChildren}
            onMouseDown={disableSwipeOnChildren}
        >
          <Box
              sx={{
                display: "flex",
                columnGap: "12px",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
          >
            <Button
                component="label"
                variant="contained"
                disabled={isUploading || isConverting}
                startIcon={(isUploading || isConverting) ? <CircularProgress size={20} color="inherit"/> :
                    <FolderIcon/>}
                sx={{
                  background: red[500],
                }}
            >
              {isConverting ? '...Converting' : 'Find'}
              <input
                  key={key}
                  type="file"
                  id="file"
                  multiple
                  style={{display: "none"}}
                  accept={"image/*"}
                  onChange={changeFile}
              />
            </Button>
            {
                files.length > 0
                &&
                <Button
                    variant="contained"
                    disabled={isUploading}
                    startIcon={isUploading ? <CircularProgress size={20} color="inherit"/> : <SendIcon/>}
                    onClick={onUpload}
                >
                  SEND
                </Button>
            }
          </Box>
          <Box
              sx={{
                overflowX: "scroll",
                display: "flex",
                columnGap: "4px",
              }}
          >
            {files.map((file, idx) => (
                <Box
                    sx={{
                      position: "relative",
                      border: "1px solid  #e2e2e2",
                    }}
                >
                  <img
                      src={URL.createObjectURL(file)}
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                  />
                  <DeleteForeverIcon
                      fontSize="large"
                      sx={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        color: red[500],
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const filterFiles = files.filter((f) => f !== file)
                        setFiles(filterFiles)
                      }}
                  />
                  {
                    progresses[idx] !== undefined
                        ? <Box sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '200px',
                          height: '200px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: 'rgba(0,0,0,0.65)'
                        }}>
                          <Typography color={'white'}>
                            {Math.round(progresses[idx])}%
                          </Typography>
                        </Box>
                        : null
                  }
                </Box>
            ))}
          </Box>
        </Box>
      </SwipeableDrawer>
  )
}
