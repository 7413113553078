import {Box, Button, Container, Divider, Typography, styled, CircularProgress} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {useLocation, useNavigate} from "react-router-dom"
import {callFunction, getDocData, queryFireStore, readRealtime, reportError} from "../hooks/firebase"
import {useEffect, useState} from "react"
import {IReservation} from "../type"
import Loading from "../components/ui/Loading"
import {parseChatMetadata, recallComprehensiveTourChat, getDevice} from "../hooks/chat"
import {deleteReservationCache, getCachedReservation, setReservationCache} from "../hooks/cacheStorage"

export default function Reservation() {
  const [reservationData, setReservationData] = useState<IReservation | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [reservationLoading, setReservationLoading] = useState<boolean>(true)

  const navigate = useNavigate()
  const location = useLocation()

  //params 이용
  const params = new URLSearchParams(location.search)
  const reservationId = params.get("id") ?? params.get("reservationId")
  const agencyCode = params.get("agencyCode")
  const targetId = agencyCode ?? reservationId

  const lang = (reservationData?.language ?? "").toLowerCase().includes("chin") ? "cn" : "en"

  useEffect(() => {
    if (!targetId) {
      alert("Cannot Access Reservation Number")
      return
    }
    setReservationLoading(true)
    getReservationInfo(targetId as string)
      .then(async (reservationData: IReservation) => {
        callFunction('presentReservation', {id: reservationData.id}).catch(console.error)
        setReservationData(reservationData)
        await setReservationCache(
          "/reservationId",
          new Response(JSON.stringify({id: targetId}), {status: 200})
        )
        await setReservationCache(
          `/${targetId}`,
          new Response(JSON.stringify(reservationData), {status: 200})
        )
      })
      .catch((e) => {
        console.error(e);
        alert("Cannot access reservation.\r\nPlease check the reservation number.\r\n*Access is valid only within 14 days.")
        backToHome()
      })
      .finally(() => {
        setReservationLoading(false)
      })
  }, [targetId])

  async function getReservationInfo(targetId: string): Promise<IReservation> {
    const retrieveReservation = async (targetId: string) => {
      const reservationsDataFromFirebase = (
        (await queryFireStore<IReservation>(
          ["reservation"],
          [
            ["agencyCode", "==", targetId],
            // ["canceledAt", "==", null],
          ]
        )) ?? []
      )
        .sort((a, b) => {
          return a.date > b.date ? 1 : -1;
        })
        .filter((r, idx, list) => {
          if (r.canceledAt) return false;
          if (list.length === 1) return true;
          const rDate = new Date(r.date);
          if (rDate.getFullYear() < new Date().getFullYear()) return false;
          if (rDate.getMonth() < new Date().getMonth()) return false;
          if (rDate.getDate() < new Date().getDate()) return false;
          return true;
        })
      if (reservationsDataFromFirebase[0]) return reservationsDataFromFirebase[0]
      const reservationDataFromFirebase = await getDocData<IReservation>(["reservation", targetId ?? ""])
      if (reservationDataFromFirebase && !reservationDataFromFirebase.canceledAt)
        return reservationDataFromFirebase
    }
    const reservation =
      (await retrieveReservation(targetId)) ??
      (await retrieveReservation(targetId.toUpperCase())) ??
      (await retrieveReservation(targetId + " ")) ??
      (await retrieveReservation(targetId.trim()))
    if (!reservation) throw new Error("No reservation found")
    const isValid = new Date(reservation?.date ?? "2000-01-01") > new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
    if (!isValid) {
      deleteReservationCache(`/${targetId}`).catch(console.error);
      throw new Error('Not a valid reservation')
    }
    const product: any = await readRealtime(`product/${reservation.productId}`)
    reservation.product = {
      id: product.id,
      key: product.key,
      name: product.name,
      area: product.area,
      category: product.category,
      chat: product.chat,
    }
    return reservation
  }

  const parseDate = (date?: string, pickupTime?: string) => {
    if (!date) return ""
    const tourDate = new Date(date)
    const year = tourDate.getFullYear()
    const month = tourDate.getMonth() + 1
    const day = tourDate.getDate()

    let tourPickupTime = ""
    if (pickupTime) {
      tourPickupTime = pickupTime.toUpperCase()
    }

    return `${year}.${month}.${day} ${tourPickupTime}`
  }

  const createChat = async () => {
    if (!reservationData) return
    setLoading(true)

    try {
      const client: any = {
        id: reservationData.id,
        name: reservationData.clientName,
        nameEn: "Client",
        type: "client",
      }
      const token = await getDevice().catch((e) => reportError(e))
      if (token) {
        client["device"] = token
        callFunction('registerReservationDevice', {reservationId: reservationData.id, device: token})
          .then(() => {
          })
          .catch(console.error)
      }

      const comprehensiveChatParams = parseChatMetadata(client, "CLIENT", {
        team: reservationData.teamId,
        date: reservationData.date,
        productId: reservationData.productId,
      })

      const chatMeta = await recallComprehensiveTourChat(comprehensiveChatParams)
      navigate(`/chat/${chatMeta.id}`, {
        state: {
          chatMeta,
          reservation: reservationData,
        },
      })
    } catch (e) {
      reportError(e)
      navigate("/")
    } finally {
      setLoading(false)
    }
  }

  const backToHome = () => {
    navigate("/", {
      state: {
        notFound: true,
      },
    })
  }

  // TODO: Add agency code
  const getAgency = (code: string) => {
    switch (code) {
      case "P":
        return "Bank of Trip / Seoulpass"
      case "BMG":
        return "BeMyGuest"
      case "BN":
        return "BnB Hero / Indiway"
      case "CHA":
        return "Changi"
      case "CV":
        return "Civitatis"
      case "COLA":
        return "Colatour"
      case "CP":
        return "Coupang"
      case "CRE":
        return "Creatrip"
      case "GG":
        return "Get Your Guide"
      case "GC":
        return "Go City"
      case "GT":
        return "GTrip Korea"
      case "HO":
        return "Headout"
      case "HP":
        return "Homepage"
      case "HT":
        return "Hopetrip"
      case "ID":
        return "Indah"
      case "KKT":
        return "Kakao"
      case "KK":
        return "KKday"
      case "L":
        return "Klook"
      case "LC":
        return "Klook China"
      case "KR":
        return "Koreal Trip"
      case "TE":
        return "Korea Travel Easy"
      case "KO":
        return "Konest / Hanchao"
      case "KT":
        return "Ktourstory"
      case "VI":
        return "Viator"
      default:
        return code
    }
  }

  const confidential = (str: string) => {
    const length = str.length
    return (
      str.replace(/\([^)]*\)/g, "").slice(0, length / 4) +
      "*".repeat(length / 2) +
      str.slice(length / 2 + 1, length)
    )
  }

  const makeup = (name: string) => {
    return name.replace(/\([^)]*\)/gi, "").toUpperCase()
  }

  if (reservationLoading) {
    return <Loading center="absolute"/>
  }
  if (!reservationData) {
    alert("Cannot find your reservation.\r\nPlease check your reservation number. Capitalization is i")
    backToHome()
    return null
  }

  return (
    <Container
      sx={{
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          py: 1.5,
        }}
      >
        <CloseIcon
          sx={{
            width: 32,
            height: 32,
          }}
          onClick={() => backToHome()}
        />
      </Box>
      <Box flex="1">
        <Box
          sx={{
            py: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 700,
              color: "#2A2F37",
            }}
          >
            {lang === "cn" ? (
              <Box>
                您好，
                <span style={{textDecoration: "underline"}}>
                                    {makeup(reservationData.clientName)}
                                </span>{" "}
                :)
              </Box>
            ) : (
              <Box>
                Hello{" "}
                <span style={{textDecoration: "underline"}}>
                                    {makeup(reservationData.clientName)}
                                </span>{" "}
                :)
              </Box>
            )}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              color: "#4F4F4F",
            }}
          >
            {lang === "cn" ? `請確認以下訂單信息 ` : `Please check the details below`}
          </Typography>
        </Box>
        <Divider/>
        <InfoBox>
          <Title>{lang === "cn" ? `參團日期` : `Date`}</Title>
          <Content>{parseDate(reservationData.date)} (KTS)</Content>
        </InfoBox>
        <InfoBox>
          <Title>{lang === "cn" ? "行程" : "Tour"}</Title>
          <Content>
            {typeof reservationData.product === "string" || !reservationData.product.chat
              ? reservationData.productId.split("_").join(" ")
              : lang === "cn"
                ? reservationData.product.chat.name?.cn ?? reservationData.productId.split("_").join(" ")
                : reservationData.product.chat.name?.en ?? reservationData.productId.split("_").join(" ")}
          </Content>
        </InfoBox>
        {reservationData.option?.length > 0 ? (
          <InfoBox>
            <Title>{lang === "cn" ? "行程選項" : "Option"}</Title>
            <Content>
              {reservationData.option.map((o) => {
                const found = Object.values<{ option: string; cn: string; en: string }>(
                  reservationData?.product?.chat?.option ?? {}
                ).find((v: { option: string; cn: string; en: string }) => v.option === o.option)
                const option = found ? (lang === "cn" ? found.cn : found.en) ?? o.option : o.option
                return (
                  <span style={{display: "block"}}>
                                        {option}: {o.people}
                                    </span>
                )
              })}
            </Content>
          </InfoBox>
        ) : null}
        <InfoBox>
          <Title>{lang === "cn" ? "人數" : "Travelers"}</Title>
          <Content>
            {lang === "cn" ? "成人" : "Adult"} : {reservationData.adult ?? 0} <br/>
            {lang === "cn" ? "兒童" : "Kid"} : {reservationData.kid ?? 0}
          </Content>
        </InfoBox>
        <InfoBox>
          <Title>{lang === "cn" ? "預約平台" : "Platform"}</Title>
          <Content>{getAgency(reservationData.agency)}</Content>
        </InfoBox>
        <InfoBox>
          <Title>{lang === "cn" ? "訂單編號" : "Reservation Number"}</Title>
          <Content>{reservationData.agencyCode}</Content>
        </InfoBox>
      </Box>

      <Box
        sx={{
          py: 2,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          rowGap: 1.25,
        }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            height: "46px",
            borderRadius: "12px",
            fontWeight: 700,
          }}
          onClick={() => createChat()}
          disabled={loading}
        >
          {loading && <CircularProgress size={20}/>}
          {!loading && lang === "cn" ? "確認" : "CONFIRM"}
        </Button>
        <Button
          variant="text"
          color={"inherit"}
          sx={{fontSize: "12px", textDecoration: "underline"}}
          onClick={() => backToHome()}
        >
          {lang === "cn" ? "不，我不是" : "No I'm not"} {makeup(reservationData.clientName)}
        </Button>
      </Box>
    </Container>
  )
}

const Title = styled(Typography)({
  color: "#828282",
  fontSize: 14,
})

const Content = styled(Typography)({
  fontSize: 14,
  fontWeight: 700,
  color: "#2A2F37",
})

const InfoBox = styled(Box)({
  padding: "12px 0",
})
