import { ButtonBase, Grid, styled, Typography } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { useLocation, useNavigate } from "react-router-dom"
import { IReservation } from "../../type"
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt"
import { useEffect, useState } from "react"
import { listenWhereFireStore, queryFireStore } from "../../hooks/firebase"
import { keyframes } from "@mui/system"

interface Props {
    title: string
    reservation: IReservation
    chatId: string
}

const spin = keyframes`
  0% {
	color: gray;
  }
  50% {
    color: #002984;
  }
	100% {
	color: gray;
	}
`

export default function ChatHeader({ title, reservation, chatId }: Props) {
    const [isExistOtherMessages, setIsExistOtherMessages] = useState<boolean>(false)
    const navigate = useNavigate()
    const unsubscribers: any[] = []
    useEffect(() => {
        (async () => {
            const data = await queryFireStore(["chats"], [["participantIds", "array-contains", reservation.id]])
            const otherChatIds = data.filter((chat: any) => chat.id !== chatId)
            otherChatIds.forEach((chat: any) => {
                const unsub = listenWhereFireStore(
                    ["chats", chat.id, "messages"],
                    [[`readStatus.${reservation.id}`, "==", false]] as any,
                    (querySnapshot) => {
                        if (querySnapshot.docs.length > 0) setIsExistOtherMessages(true)
                    }
                )
                unsubscribers.push(unsub)
            })
        })()

        return () => {
            if (unsubscribers.length) {
                unsubscribers.forEach((unsub) => unsub())
            }
        }
    }, [])
    return (
        <Grid
            container
            sx={{
                position: "sticky",
                top: 0,
                background: "#fff",
                zIndex: 1,
                py: 1.25,
                px: 2.5,
            }}
        >
            <Grid
                item
                xs={4}
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <ButtonBase
                    onClick={() =>
                        navigate("/chat", {
                            state: {
                                reservation,
                            },
                        })
                    }
                >
                    {isExistOtherMessages ? (
                        <MarkUnreadChatAltIcon
                            sx={{
                                width: "28px",
                                height: "28px",
                                animation: `${spin} .9s  infinite`,
                            }}
                        />
                    ) : (
                        <ChevronLeftIcon
                            sx={{
                                width: "32px",
                                height: "32px",
                            }}
                        />
                    )}
                </ButtonBase>
            </Grid>
            <Grid
                item
                xs={4}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>
    )
}
