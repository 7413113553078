import React, { useCallback, useEffect } from "react"
import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom"
import Home from "./pages/Home"
import Reservation from "./pages/Reservation"
import Chats from "./pages/Chats"
import Chat from "./pages/Chat"
import { ChatContextProvider } from "./context/ChatContext"

function ErrorBoundary() {
    return (
        <div>
            Unauthorized access, Please access the <a href="/">site</a>{" "}
        </div>
    )
}

export const routesConfig = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/reservation",
        element: <Reservation />,
    },
    {
        path: "/chat",
        element: <Chats />,
    },
    {
        path: "/chat/:id",
        element: (
            <ChatContextProvider>
                <Chat />
            </ChatContextProvider>
        ),
        errorElement: <ErrorBoundary />,
    },
]
const router = createBrowserRouter(routesConfig)
function App() {
    const chatNotificationClickHandler = useCallback(
        (event: any) => {
            if (event.data.action === "chat-notification-clicked") {
                window.focus()
                window.location.href = event.data.url
            }
        },
        [window]
    )
    useEffect(() => {
        if (window && window.navigator && navigator.serviceWorker) {
            window.navigator.serviceWorker.addEventListener("message", chatNotificationClickHandler)
        }
        return () => {
            if (window && window.navigator && navigator.serviceWorker) {
                window.navigator.serviceWorker.removeEventListener("message", chatNotificationClickHandler)
            }
        }
    }, [])
    return <RouterProvider router={router} />
}

export default App
