import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { SyntheticEvent, useEffect, useState } from "react"
import MoreAccordion from "../components/chat/MoreAccordion"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import ChatRoom from "../components/chat/ChatRoom"
import {
    callFunction,
    getDeviceToken,
    getDocData,
    queryFireStore,
    queryWhereFireStore,
    requestNotificationPermission,
    updateDocData,
} from "../hooks/firebase"
import {IChatMeta, ICreateChatParams, IGuide, IParticipant, IReservation, ITour} from "../type"
import Loading from "../components/ui/Loading"
import { getDevice, parseChatMetadata, recallComprehensiveTourChat } from "../hooks/chat"
import {getUserBrowser, getUserDevice} from "../utils";

export default function Chats() {
    const navigate = useNavigate()
    const location = useLocation()
    const { reservation } = location.state
    const [expanded, setExpanded] = useState<number>(0)
    const [tour, setTour] = useState<string>(
        reservation?.tourId.includes("알남레")
            ? `Alpaca World+Nami Island+ Rail bike One Day Tour\r\n草泥馬(羊駝)牧場 + 南怡島 + 江村鐵道`
            : reservation?.tourId.split("_").join(" ") ?? ""
    )
    const [chatMetas, setChatMetas] = useState<IChatMeta[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        async function getChat() {
            setLoading(true)
            const chats = await queryFireStore(["chats"], [["participantIds", "array-contains", reservation.id]])

            setChatMetas(chats as IChatMeta[])
            setLoading(false)
        }
        getChat()
    }, [])

    const onChangeTour = (event: SelectChangeEvent) => {
        setTour(event.target.value as string)
    }

    const onChangeAccordionPanel = (panel: number) => (_: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : 0)
    }

    const convertDateFormat = (date: string) => {
        return date.substring(2).replaceAll("-", ".")
    }

    const createChat = async () => {
        if (!reservation) return
        setLoading(true)

        try {
            const client: any = {
                id: reservation.id,
                name: reservation.clientName,
                nameEn: "Client",
                type: "client",
            }
            const token = await getDevice().catch((e) => reportError(e))
            if (token) {
                client["device"] = token
            }

            const comprehensiveChatParams = parseChatMetadata(client, "CLIENT-GUIDE", {
                team: reservation.teamId,
                date: reservation.date,
                productId: reservation.productId,
            }, reservation)

            const chatMeta = await recallComprehensiveTourChat(comprehensiveChatParams)
            navigate(`/chat/${chatMeta.id}`, {
                state: {
                    chatMeta,
                    reservation,
                },
            })
        } catch (e) {
            reportError(e)
            navigate("/")
        } finally {
            setLoading(false)
        }
    }

    const createPromotionChat = async () => {
        if (!reservation) return
        setLoading(true)

        try {
            const client: any = {
                id: reservation.id,
                name: reservation.clientName,
                nameEn: "Client",
                type: "client",
            }
            const token = await getDevice().catch((e) => reportError(e))
            if (token) {
                client["device"] = token
            }

            const tour = {
                team: reservation.teamId,
                date: reservation.date,
                productId: reservation.productId,
            };
            const browser = getUserBrowser()
            const device = getUserDevice()

            const comprehensiveChatParams = {
                category:'CS-PROMOTION',
                participant: {
                    id: reservation.id,
                    name: reservation.clientName,
                    nameEn: "Client",
                    type: "client",
                    agent: {
                        device,
                        browser,
                    },
                },
                cId: `CS-PROMOTION:${reservation.id}`,
                title: `${reservation.clientName} 프로모션 문의`,
                tour,
            }

            const chatMeta = await recallComprehensiveTourChat(comprehensiveChatParams)
            navigate(`/chat/${chatMeta.id}`, {
                state: {
                    chatMeta,
                    reservation,
                },
            })
        } catch (e) {
            reportError(e)
            navigate("/")
        } finally {
            setLoading(false)
        }
    }

    const isValid = new Date(reservation?.date ?? "2000-01-01") > new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
    if (!isValid) {
        alert("Please check your reservation Id.\r\nAccess is valid only within 14 days.")
        navigate("/")
    }

    return (
        <Container
            sx={{
                overflow: "scroll",
                display: "flex",
                flexDirection: "column",
                px: 2.5,
                height: "100dvh",
            }}
        >
            <Box
                sx={{
                    position: "sticky",
                    background: "#fff",
                    top: 0,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        py: 1.5,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "18px",
                            fontWeight: 700,
                        }}
                    >
                        Chat
                    </Typography>
                    {/*<Box>*/}
                    {/*    <NotificationsOffIcon*/}
                    {/*        sx={{*/}
                    {/*            fill: "#bdbdbd",*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                </Box>
                <Box>
                    <Box
                        sx={{
                            borderBottom: "2px solid #2a2f37",
                            width: "fit-content",
                            pb: 1,
                        }}
                    >
                        {convertDateFormat(reservation.date)}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "16px 0 24px 0",
                    }}
                >
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="tour-select-label">Tour</InputLabel>
                        <Select
                            labelId="tour-select-label"
                            value={tour}
                            onChange={onChangeTour}
                            label="Tour"
                            sx={{
                                "& svg": {
                                    fill: "#2a2f37",
                                },
                                "& .MuiSelect-select": {
                                    fontSize: "20px",
                                    fontWeight: 700,
                                },
                                "& .MuiSelect-select:focus": {
                                    background: "#fff",
                                },
                            }}
                        >
                            <MenuItem value={tour}>
                                <em>{tour}</em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        width:'100%',
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom:2,
                    }}
                >
                    {/*<Button onClick={createPromotionChat}>*/}
                    {/*    Special Offer*/}
                    {/*</Button>*/}
                    <Button onClick={createChat}>
                        Contact to Guides
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                }}
            >
                {!loading ? (
                    chatMetas.length > 0 ? (
                        chatMetas.map((chatMeta) => (
                            <ChatRoom key={chatMeta.id} chatMeta={chatMeta} reservation={reservation} />
                        ))
                    ) : (
                        <Typography>No Guide is deployed yet.</Typography>
                    )
                ) : (
                    <Loading center="flex" />
                )}
            </Box>
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        position: "sticky",*/}
            {/*        bottom: 0,*/}
            {/*        py: 0.5,*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: "column",*/}
            {/*        gap: "4px",*/}
            {/*        background: "#fff",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <MoreAccordion*/}
            {/*        expanded={expanded === 1}*/}
            {/*        title="Pickup & Reservation"*/}
            {/*        summary="You can check reservation info right here"*/}
            {/*        onChange={onChangeAccordionPanel(1)}*/}
            {/*    >*/}
            {/*        <Typography>Pickup Reservation</Typography>*/}
            {/*    </MoreAccordion>*/}
            {/*    <Box>*/}
            {/*        <MoreAccordion*/}
            {/*            expanded={expanded === 2}*/}
            {/*            title="Ask AI"*/}
            {/*            summary="Ask question about AI"*/}
            {/*            onChange={onChangeAccordionPanel(2)}*/}
            {/*        >*/}
            {/*            <Typography>Ask AI</Typography>*/}
            {/*        </MoreAccordion>*/}
            {/*    </Box>*/}
            {/*    <Box>*/}
            {/*        <MoreAccordion*/}
            {/*            expanded={expanded === 3}*/}
            {/*            title="Notice & FAQ"*/}
            {/*            summary="Question frequently asked by travelers"*/}
            {/*            onChange={onChangeAccordionPanel(3)}*/}
            {/*        >*/}
            {/*            <Typography>Pickup Reservation</Typography>*/}
            {/*        </MoreAccordion>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
        </Container>
    )
}
