import {
    QueryDocumentSnapshot,
    QuerySnapshot,
    serverTimestamp,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore"
import {
    callFunction,
    collectionWithBaseFireStore, docWithBaseFireStore,
    getDeviceToken,
    getDocData,
    reportError,
    requestNotificationPermission,
    setDocData,
    updateDocData,
} from "./firebase"
import { IChatMeta, ICreateChatParams, IMessage, IParticipant, ITour, ISender, IReservation } from "../type"
import { getUserBrowser, getUserDevice } from "../utils"

export const subscribeToNewMessages = (
    chatId: string,
    lastMessageDate: Date,
    callback: (snapshot: QuerySnapshot<any>) => void
) => {
    return onSnapshot(
        query(
            collectionWithBaseFireStore(["chats", chatId, "messages"]),
            orderBy("date", "asc"),
            where("date", ">", lastMessageDate)
        ),
        callback
    )
}

export const separateMessages = (messages: IMessage[]): IMessage[][] => {
    const separatedMessages: IMessage[][] = []
    let messageGroup: IMessage[] = []
    let prevSenderId: string | null = null
    let lastMinute: number = 0

    messages.forEach((message: IMessage, index: number) => {
        const { type, sender, date: messageDate } = message

        const nowDate = new Date(messageDate.seconds * 1000)
        const currentMinute = nowDate.getHours() * 60 + nowDate.getMinutes()

        if (type === "exit" || type === "enter" || type === "date") {
            if (messageGroup.length > 0) {
                separatedMessages.push(messageGroup)
                messageGroup = []
            }
            separatedMessages.push([message])
            messageGroup = []
            return
        }
        if (messageGroup.length > 0 && (currentMinute !== lastMinute || prevSenderId !== sender.id)) {
            separatedMessages.push(messageGroup)
            messageGroup = []
        }

        messageGroup.push(message)
        lastMinute = currentMinute
        prevSenderId = sender.id

        if (index === messages.length - 1) {
            if (messageGroup.length > 0) {
                separatedMessages.push(messageGroup)
            }
        }
    })

    return separatedMessages
}

export const updateReadStatusMessage = async (chatId: string, uid: string) => {
    const ref = collectionWithBaseFireStore(["chats", chatId, "messages"])
    const querySnapshot = await getDocs(query(ref, where("readStatus." + uid, "==", false)))

    querySnapshot.forEach((doc: QueryDocumentSnapshot<unknown>) => {
        updateDoc(doc.ref, { [`readStatus.${uid}`]: true })
    })

    const chatRef = docWithBaseFireStore(['chats', chatId]);
    const readAt = new Date().toString();
    updateDoc(chatRef, {[`readDate.${uid}`]: readAt}).catch(console.error);
}

export const sendMessage = async (
    chatId: string,
    message: {
        type: string
        text?: string
        files?: string[]
    } = {
        type: "text",
        text: "",
        files: [],
    },
    sender: IReservation,
    reply?: {
        id: string
        text: string
    }
) => {
    const { participants } = (await getDocData(["chats", chatId])) as any
    if (Object.values(participants).length <= 1) {
        alert("채팅방에 참여자가 없습니다.")
        return
    }
    const readStatus = Object.keys(participants).reduce((r: any, p: any) => {
        return {
            ...r,
            [p]: p === sender.id,
        }
    }, {})

    let data = {}
    switch (message.type) {
        case "text": {
            data = {
                text: message.text,
                readStatus,
            }
            break
        }
        case "image": {
            data = {
                files: message.files,
                readStatus,
            }
            break
        }
        case "reply": {
            data = {
                text: message.text,
                reply,
                readStatus,
            }
        }
    }

    await setDocData(["chats", chatId, "messages"], {
        type: message.type,
        date: serverTimestamp(),
        sender: {
            type: "client",
            name: sender.clientName,
            nameEn: sender.clientName,
            id: sender.id,
            photoURL: "https://cdn-icons-png.flaticon.com/512/5894/5894085.png",
        },
        ...data,
    })
    await updateDocData(["chats", chatId], {
        updatedAt: serverTimestamp(),
    })
}

export const recallComprehensiveTourChat = async ({
    category,
    participant,
    cId,
    title,
    tour,
}: ICreateChatParams): Promise<IChatMeta> => {
    const chat = (await callFunction("recallComprehensiveTourChat", {
        category,
        participant,
        cId,
        title,
        tour,
    })) as IChatMeta

    return chat
}

export const parseChatMetadata = (
    participant: IParticipant,
    category: string = "CLIENT",
    tour: ITour,
    reservation?: IReservation
): ICreateChatParams => {
    const { productId, date, team } = tour
    const cId =
        category === "CLIENT-GUIDE" && reservation
            ? `${category}:${productId}:${date}:${team}:${reservation.id}`
            : `${category}:${productId}:${date}:${team}`
    const title =
        category === "CLIENT-GUIDE" && reservation
            ? `${date}:${productId.split("_").pop()}:${reservation.clientName}:GUIDE문의`
            : `${date}:${productId.split("_").pop()}`
    const browser = getUserBrowser()
    const device = getUserDevice()

    return {
        category,
        participant: {
            ...participant,
            agent: {
                device,
                browser,
            },
        },
        cId,
        title,
        tour,
    }
}

export const getDevice = async () => {
    const permission = await requestNotificationPermission()
    if (permission === "granted") {
        const registerDeviceToken = async () => {
            const token = await getDeviceToken()
            return token
        }
        try {
            return await registerDeviceToken()
        } catch (e) {
            reportError(e)
            //todo - 알림 허용을 목적으로 재시도 로직 pushManager, No Active Service Worker 에러가 없어지면, 제거 고려
            return await new Promise((resolve) => {
                setTimeout(() => {
                    registerDeviceToken()
                        .then(resolve)
                        .catch((e) => {
                            reportError(e)
                        })
                }, 500)
            })
        }
    }
    return null
}

export const convertSenderName = (sender: ISender) => {
    switch (sender.type) {
        case "guide":
            return `Guide(${sender.nameEn},${sender.name})`
        case "operator":
            return `Office`
        default:
            return sender.name.replace(/\(.+\)/i, "")
    }
}

export const isParticipant = async (
    chatId: string,
    id: string
): Promise<{
    isExist: boolean
    participants: {
        [id: string]: {
            id: string
            name: string
        }
    }
}> => {
    const { participants } = (await getDocData(["chats", chatId])) as any
    const isExist = Object.keys(participants).includes(id)
    return {
        isExist,
        participants,
    }
}

export const isInfoMessage = (message: IMessage) => {
    switch (message.type) {
        case "exit":
        case "enter":
        case "date":
            return true
        default:
            return false
    }
}
