import { Box, Typography } from "@mui/material"
import { IChatMeta, IReservation } from "../../type"
import { collectionWithBaseFireStore, listenWhereFireStore } from "../../hooks/firebase"
import { useEffect, useState } from "react"
import { limit, onSnapshot, orderBy, query } from "firebase/firestore"
import { useNavigate } from "react-router-dom"

interface Props {
    chatMeta: IChatMeta
    reservation: IReservation
}

export default function ChatRoom({ chatMeta, reservation }: Props) {
    const [unreadCount, setUnreadCount] = useState<number>(0)
    const [latestMessage, setLatestMessage] = useState<any>(null)
    const guideNames = Object.values(chatMeta.participants)
        .filter((participant) => participant.id !== reservation.id)
        .map((participant) => participant.name)

    const navigate = useNavigate()

    useEffect(() => {
        const unsub = listenWhereFireStore(
            ["chats", chatMeta.id, "messages"],
            [[`readStatus.${reservation.id}`, "==", false]] as any,
            (querySnapshot) => {
                setUnreadCount(querySnapshot.docs.length)
            }
        )

        return () => {
            if (unsub) unsub()
        }
    }, [])

    useEffect(() => {
        const unsub = onSnapshot(
            query(collectionWithBaseFireStore(["chats", chatMeta.id, "messages"]), orderBy("date", "desc"), limit(1)),
            (querySnapshot) => {
                querySnapshot.forEach((doc: any) => {
                    if (doc.exists()) {
                        setLatestMessage(doc.data() as any)
                    }
                })
            }
        )

        return () => {
            if (unsub) unsub()
        }
    }, [])

    return (
        <Box
            sx={(theme) => ({
                p: 2,
                display: "flex",
                columnGap: 1.5,
                width: "100%",
                border: `1px solid ${theme.palette.grey["700"]}`,
                borderRadius: "16px",
                transition: "background .8s",
                "&:hover": {
                    background: "#e9f4ff",
                },
            })}
            onClick={() =>
                navigate(chatMeta.id, {
                    relative: "path",
                    state: {
                        chatMeta,
                        reservation,
                    },
                })
            }
        >
            {/*<Box>*/}
            {/*    <img*/}
            {/*        src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"*/}
            {/*        style={{*/}
            {/*            width: "60px",*/}
            {/*            height: "60px",*/}
            {/*            borderRadius: "50%",*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Box>*/}
            <Box
                sx={{
                    flex: 1,
                }}
            >
                <Typography
                    sx={{
                        textAlign: "right",
                        fontSize: "12px",
                        color: "#828282",
                    }}
                >
                    {latestMessage?.date?.toDate().toLocaleTimeString().split(":").slice(0, 2).join(":")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "18px",
                        color: "var(--black)",
                        fontWeight: 700,
                        textTransform: "uppercase",
                    }}
                >
                    {chatMeta.category === "PERSONAL" &&
                        `chat with your guide(${
                            Object.values(chatMeta.participants).filter((p) => p.id !== reservation.id)[0].nameEn
                        })`}
                    {chatMeta.category === "CS" && "OFFICE"}
                    {chatMeta.category === "CS-PROMOTION" && "SPECIAL OFFER"}
                    {chatMeta.category === "CLIENT-GUIDE" && "GUIDE"}
                    {chatMeta.category === "CLIENT-OPERATOR" && "OFFICE"}
                    {chatMeta.category === "CLIENT" && "TOUR"}
                </Typography>
                {/*<Typography*/}
                {/*    sx={{*/}
                {/*        fontSize: "16px",*/}
                {/*        color: "var(--black)",*/}
                {/*        fontWeight: 700,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {guideNames.join(", ")}*/}
                {/*</Typography>*/}

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: "#4f4f4f",
                            textAlign: "left",
                        }}
                    >
                        {latestMessage?.type === "text" || latestMessage?.type === "reply"
                            ? latestMessage?.text
                            : latestMessage?.type === "image"
                            ? "이미지"
                            : ""}
                    </Typography>
                    {unreadCount > 0 && (
                        <Box
                            sx={{
                                width: "24px",
                                height: "24px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                                fontSize: "12px",
                                fontWeight: 700,
                                background: "#FF4C41",
                                borderRadius: "50%",
                            }}
                        >
                            {unreadCount}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
