import * as Sentry from "@sentry/react"

import {getApps, initializeApp} from "firebase/app"
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    Query,
    QuerySnapshot,
    setDoc,
    updateDoc,
    where,
    WhereFilterOp,
} from "firebase/firestore"
import {saveAs} from "file-saver"
import {
    getDatabase,
    ref as dbRef,
    onValue,
    get,
    remove,
    set,
    update,
    push,
    query as queryRealtime,
    orderByChild,
    orderByValue,
    orderByKey,
    equalTo,
    limitToFirst,
    limitToLast,
    startAt,
    startAfter,
    endAt,
    endBefore,
} from "firebase/database"

import {getFunctions, httpsCallable} from "firebase/functions"
import {getMessaging, getToken, isSupported} from "firebase/messaging"
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage"
import {getAnalytics, logEvent} from "firebase/analytics"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const apps = getApps()
const app = apps.length > 1 ? apps[0] : initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export function reportAnalytics(eventName: string, message: string, params: { [key: string]: any } = {}) {
    logEvent(analytics, eventName, {message, ...params})
}

export function reportLog(message: string, params: { [key: string]: any } = {}) {
    reportAnalytics("system", message, params)
}

export function reportError(error: Error | any, params: { [key: string]: any } = {}) {
    reportAnalytics(error.name ? `err_${error.name}` : "error", error.message ?? error.toString(), {
        name: error.name ?? "",
        ...params,
    })
    Sentry.captureException(error)
    console.error(error)
}

const functions = getFunctions(app)

export async function callFunction<S>(name: string, data: unknown) {
    const callable = httpsCallable(functions, name, {timeout: 540000})
    const result = await callable(data)
    return result.data as S
}

const firestore = getFirestore(app)

export function docWithBaseFireStore(paths: string[]) {
    const [path, ...pathSegments] = paths
    return doc(firestore, path, ...pathSegments)
}

export function collectionWithBaseFireStore(paths: string[]) {
    const [path, ...pathSegments] = paths
    return collection(firestore, path, ...pathSegments)
}

export async function getDocData<T>(paths: string[]): Promise<T> {
    const ref = docWithBaseFireStore(paths)
    const doc = await getDoc(ref)
    return doc.data() as T
}

export async function setDocData(paths: string[], data: { [key: string]: any }, options?: any) {
    if (paths.length % 2 === 0) {
        const ref = docWithBaseFireStore(paths)
        await setDoc(ref, data, options)
        return
    }

    const ref = collectionWithBaseFireStore(paths)
    return await addDoc(ref, data)
}

export async function updateDocData(paths: string[], data: { [key: string]: any }) {
    const ref = docWithBaseFireStore(paths)
    await updateDoc(ref, data)
}

export function queryWhereFireStore(paths: string[], queries: any[]) {
    const wheres = queries.map(([property, operator, value]: any) => where(property, operator as WhereFilterOp, value))
    return query(collectionWithBaseFireStore(paths), ...wheres)
}

export async function queryFireStore<S>(collection: string[], queries: any[]) {
    const snapshot = await getDocs(queryWhereFireStore(collection, queries))
    const result: S[] = []
    if (snapshot.size === 0) return result
    snapshot.forEach((d) => {
        result.push({id: d.id, ...d.data()} as S)
    })
    return result
}

export function listenWhereFireStore(
    paths: string[],
    queries: Query[],
    onNext: (snapshot: QuerySnapshot<unknown>) => void,
    onError: (error: Error) => void = console.log
) {
    return onSnapshot(queryWhereFireStore(paths, queries), onNext, onError)
}

export async function requestNotificationPermission() {
    try {
        const permission = await Notification.requestPermission()
        return permission
    } catch (e) {
        const userAgent = window.navigator.userAgent.toLowerCase()
        const iphone = /iPhone|iPad|iPod/i.test(userAgent)
        if (iphone) {
            alert("If you'd like to receive notifications  Select 'Add Home Screen' to download the app.")
        }
        return null
    }
}

export async function getDeviceToken() {
    const messaging = (await isSupported()) ? getMessaging(app) : null

    if (messaging) {
        return await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        })
    } else {
        return null
    }
}

const storage = getStorage(app)

export async function uploadFileToStorage(path: string, file: Blob, onProgress: (progress: number, transferredBytes: number, totalBytes: number,) => void) {
    return new Promise<string>((resolve, reject) => {
        const storageRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress(progress, snapshot.bytesTransferred, snapshot.totalBytes);
            },
            (error) => {
                reject(error)
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
                resolve(downloadUrl)
            }
        )
    })
}

export async function downloadFileFromStorage(path: string) {
    const resp = await fetch(path)
    const blob = await resp.blob()
    let blobUrl = window.URL.createObjectURL(blob)
    saveAs(blobUrl, path.split("token=")[1])

}

const database = getDatabase(app)

export function refWithBaseDatabase(path: string) {
    return dbRef(database, path)
}

export async function readRealtime<S>(path: string): Promise<S> {
    const snapshot = await get(refWithBaseDatabase(path))
    return snapshot.val() as S
}
